import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VContainer,[_c(VCard,{attrs:{"flat":""}},[_c(VForm,{model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VTextField,{attrs:{"disabled":_vm.loading,"label":"First Name"},model:{value:(_vm.person.first_name),callback:function ($$v) {_vm.$set(_vm.person, "first_name", $$v)},expression:"person.first_name"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Surname"},model:{value:(_vm.person.surname),callback:function ($$v) {_vm.$set(_vm.person, "surname", $$v)},expression:"person.surname"}})],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VTextField,{attrs:{"disabled":_vm.loading,"label":"Email"},model:{value:(_vm.person.email),callback:function ($$v) {_vm.$set(_vm.person, "email", $$v)},expression:"person.email"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.status,"menu-props":{ maxHeight: '400' },"label":"Status","item-text":"title","item-value":"id","clearable":""},model:{value:(_vm.person.people_statuses_id),callback:function ($$v) {_vm.$set(_vm.person, "people_statuses_id", $$v)},expression:"person.people_statuses_id"}})],1),_c(VCol,{attrs:{"cols":"12","md":"6"}},[_c(VSelect,{attrs:{"disabled":_vm.loading,"items":_vm.lists.types,"menu-props":{ maxHeight: '400' },"label":"Type","item-text":"title","item-value":"id","clearable":""},model:{value:(_vm.person.people_types_id),callback:function ($$v) {_vm.$set(_vm.person, "people_types_id", $$v)},expression:"person.people_types_id"}})],1)],1),_c(VRow,[_c(VCol,{staticClass:"text-right",attrs:{"cols":"12"}},[_c(VBtn,{staticClass:"primary button--search",attrs:{"disabled":_vm.loading},on:{"click":_vm.searchAction}},[_vm._v("Search")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }